import wrapWithProvider from "./wrap-with-provider";
export const wrapRootElement = wrapWithProvider;

const addJS = (jsCode) => {
  let s = document.createElement(`script`);
  s.type = `text/javascript`;
  s.innerText = jsCode;
  document.getElementsByTagName(`head`)[0].appendChild(s);
};
const removeCWScripts = () => {
  document
    .querySelectorAll('[src^="https://3pi.choosewisely.co.uk"]')
    .forEach((el) => {
      el.remove();
    });
  document.querySelectorAll("#clearChoice_config").forEach((el) => {
    el.remove();
  });
  document
    .querySelectorAll('[href^="https://3pi.choosewisely.co.uk"]')
    .forEach((el) => {
      el.remove();
    });
};
const injectJS = (jsCode) => {
  var s = document.createElement(`script`);
  s.async = true;
  s.type = `text/javascript`;

  s.src = jsCode;
  document.getElementsByTagName(`head`)[0].appendChild(s);
};

const injectReviewsScript = () => {
  addJS(`
  var reviewsJsonLdRendered = reviewsJsonLdRendered ? reviewsJsonLdRendered : !1
  , floatingWidgetPosition = "";
    window.richSnippetReviewsWidgets = function() {
    function e(e) {
        var t = 200;
        switch (e) {
        case "carousel":
            t = 297;
            break;
        case "floating-widget":
            t = 0;
            break;
        case "text-banner":
            t = 43;
            break;
        default:
            t = 200
        }
        return t
    }
    var t, n, a;
    arguments.length > 1 ? (t = arguments[0],
    a = document.getElementById(t),
    n = arguments[1]) : (n = arguments.length > 0 ? arguments[0] : {},
    t = "richSnippetReviewsWidget-defaultContainer",
    a = document.createElement("div"),
    a.setAttribute("id", t),
    document.body.appendChild(a));
    var r = new XMLHttpRequest;
    r.onreadystatechange = function() {
        if (4 === this.readyState && !reviewsJsonLdRendered && 200 == this.status) {
            var e = this.responseText
              , t = document.createElement("script")
              , i = JSON.parse(e);
            n.data && "WebPage" !== i["@type"] && (reviews = JSON.parse(e),
            reviews = richSnippetRecursiveMerge(reviews, n.data),
            e = JSON.stringify(reviews)),
            t.setAttribute("type", "application/ld+json"),
            t.appendChild(document.createTextNode(e)),
            document.getElementsByTagName("head")[0].appendChild(t),
            reviewsJsonLdRendered = !0
        }
    }
    ,
    richSnippetRecursiveMerge = function(e, t) {
        for (var i in t)
            try {
                t[i].constructor == Object ? e[i] = richSnippetRecursiveMerge(e[i], t[i]) : e[i] = t[i]
            } catch (n) {
                e[i] = t[i]
            }
        return e
    }
    ,
    r.open("GET", "https://api.reviews.io/json-ld/company/snippet?store=" + n.store + "&url=" + document.location.href, !0),
    r.send();
    var d = "https://widget.reviews.io/rich-snippet-reviews-widgets/widget"
      , o = "version=13";
    n.elementId = t,
    n.origin = window.location.href,
    n.floatPosition && "right" == n.floatPosition && (floatingWidgetPosition = "--right");
    for (i in n)
        "sku" == i && Array.isArray(n[i]) && (n[i] = n[i].join(";")),
        o.length > 0 && (o += "&"),
        o += "object" != typeof n[i] ? i + "=" + encodeURIComponent(n[i]) : i + "=" + encodeURIComponent(JSON.stringify(n[i]));
    var s = document.createElement("IFRAME");
    s.name += t + "_frame",
    s.id += t + "_frame",
    s.setAttribute("frameborder", 0),
    s.width = "100%",
    s.scrolling = "no",
    s.maxWidth = "100%",
    s.minWidth = "100%",
    s.height = e(null != n.widgetName ? n.widgetName : ""),
    s.title = "Reviews Widget",
    a.innerHTML = "",
    a.appendChild(s),
    window.onresize = function() {
        s.width = window.getComputedStyle(a, null).getPropertyValue("width"),
        s.style.width = window.getComputedStyle(a, null).getPropertyValue("width") + "px"
    }
    ;
    var c = o.length > 1e3 ? "post" : "get";
    if ("get" == c)
        s.setAttribute("src", d + "?" + o);
    else {
        var l = document.createElement("form");
        l.style = "display:none;",
        l.method = "POST",
        l.action = d,
        l.target = t + "_frame";
        for (var g in n) {
            var p = document.createElement("input");
            p.type = "hidden",
            p.value = n[g],
            p.name = g,
            l.appendChild(p)
        }
        document.body.appendChild(l),
        l.submit()
    }
    var h = function(e) {
        var i;
        try {
            var n = JSON.parse(e.data);
            if (void 0 != n.action)
                switch (i = window.document.querySelector("iframe#" + t + "_frame"),
                n.action) {
                case "resize":
                    n.elementId == t && (s.height = n.height);
                    break;
                case "resize-load":
                    s.height = n.height;
                    break;
                case "RUKFloatingWidget" + floatingWidgetPosition + "#Ready":
                    "richSnippetReviewsWidget-defaultContainer" == t && i.classList.add("RUKFloatingWidget" + floatingWidgetPosition);
                    break;
                case "RUKFloatingWidget" + floatingWidgetPosition + "#Expand":
                    "richSnippetReviewsWidget-defaultContainer" == t && i.classList.add("expanded");
                    break;
                case "RUKFloatingWidget" + floatingWidgetPosition + "#Retract":
                    "richSnippetReviewsWidget-defaultContainer" == t && i.classList.remove("expanded")
                }
        } catch (a) {}
    };
    window.addEventListener ? addEventListener("message", h, !1) : attachEvent("onmessage", h)
},
"function" == typeof richSnippetReviewsWidgetsListenerCallback && richSnippetReviewsWidgetsListenerCallback();
  `);
};

let injectedReviewsScript = false;

const embedClasses = `#text-banner-widget`;
const ccApply = `#ccApply`;

export const onRouteUpdate = ({ location }) => {
  if (
    /\/apply/.test(location.pathname) &&
    document.querySelector(ccApply) !== null
  ) {
    injectJS("https://3pi.choosewisely.co.uk/ccLoader.js");
  } else {
    removeCWScripts();
  }

  // If there's an embedded element, lazy-load the twitter script (if it hasn't
  // already been loaded), and then run the twitter load function.
  if (document.querySelector(embedClasses) !== null) {
    if (!injectedReviewsScript) {
      injectReviewsScript();
      injectedReviewsScript = true;
    }

    if (typeof window.richSnippetReviewsWidgets === `function`) {
      window.richSnippetReviewsWidgets("text-banner-widget", {
        store: "themoneystore-co",
        starsClr: "#f47e27",
        textClr: "#313131",
        logoClr: "#12cf6c",
        widgetName: "text-banner",
      });
    }
  }
};
