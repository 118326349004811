import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import initialState from './initialState';
import reducer from '../redux/reducer';
import devtools from './devtools';

const initStore = () => {
  const store = createStore(
    reducer,
    initialState,
    compose(
      applyMiddleware(thunk),
      devtools
    )
  );

  return store;
};

export default initStore;
