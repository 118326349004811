import queryString from 'query-string';
import * as f from '../constants/fieldNames';
import * as d from '../constants/defaults';
import { POWERED_BY_AFFILIATE_REF } from '../constants/api';

// Using localStorage for the GCLID instead of state due to commit "1969b20cf9a636e798f38d330ab92511cf1d1a95"
// no longer using Gatsby <Link/> for navigation and state being reinitialised on navigation.

let G_CLID = POWERED_BY_AFFILIATE_REF;
if (typeof window !== `undefined`) {
  const QUERY_PARAM_GCLID = queryString.parse(window.location.search).gclid;
  const LS_KEY = '__gclid';
  const NOW = new Date().getTime();
  const TTL = NOW + (60000 * 60 * 24 * 90); // 90 days GCLID TTL

  if (QUERY_PARAM_GCLID) {
    const gclidObject = {
      value: QUERY_PARAM_GCLID,
      ttl: TTL,
    };
    localStorage.setItem(LS_KEY, JSON.stringify(gclidObject));
  }

  if (localStorage.getItem(LS_KEY)) {
    const {
      value,
      ttl,
    } = JSON.parse(localStorage.getItem(LS_KEY));
    if (NOW > ttl) {
      localStorage.removeItem(LS_KEY);
    }
    G_CLID = value;
  }
}

const state = {
  data: {
    [f.AFFILIATE_REF]: null,
    [f.GCLID]: G_CLID,
    [f.LOAN_AMOUNT]: d.LOAN_AMOUNT,
    [f.LOAN_TERM]: d.LOAN_TERM,
  },
};

export default state;
