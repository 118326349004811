import update from "immutability-helper";
import * as t from "./actionTypes";
import initialState from "./../state/initialState";

const reducer = (currentState = initialState, action) => {
  const { type, payload } = action;
  let state = currentState;
  switch (type) {
    case t.UPDATE_FORM_FIELD: {
      const { field, value } = payload;
      if (typeof window !== "undefined") {
        window.__loanAmount = window.localStorage.setItem(`__${field}`, value);
      }

      return update(state, {
        data: { [field]: { $set: value } },
      });
    }
    default:
  }

  return state;
};

export default reducer;
